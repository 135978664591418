import * as React from "react";
import { graphql } from "gatsby";
import { inject } from "mobx-react";

import { Seo, GalleryPageCollection } from 'components';
import { SquidexGalleryOverviewPage, SquidexGalleryItemConnection } from 'generated/gatsbyApiTypes';
import { TraumwandPageComponent, PAGE_VIEW_EVENT, NAVIGATE_EVENT, getLanguageValue} from 'utils';

export interface QueryData
{
  me: SquidexGalleryOverviewPage;
  galleries: SquidexGalleryItemConnection;
}

@inject("visitorStore")
export default class GalleryOverviewPage extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "gallery-overview"
    });
  }
  render() {
    const pageTitle = getLanguageValue(this.props.data.me.title);
    const pageText = getLanguageValue(this.props.data.me.text).html;
    return (
      <>
        <Seo subTitle={pageTitle} />
        <h1>{pageTitle}</h1>
        <div className="markdown" dangerouslySetInnerHTML={{ __html: pageText }} />
        <GalleryPageCollection nodes={this.props.data.galleries.nodes} navigate={this.navigate} />
      </>
    )
  }
  navigate = (link: string) => {
    if (!!link) {
      this.props.visitorStore.logEvent(NAVIGATE_EVENT, {
        location: this.props.location,
        href: link
      });
      this.props.navigate(link);
    }
  }
}

export const query = graphql`
{
  me: squidexGalleryOverviewPage(identity: {eq: "galleryOverview"}) {
    title {
      de
      nl
      en
    }
    text {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    btnText {
      de
      nl
      en
    }
  }
  galleries: allSquidexGallery(sort: {order:DESC, fields: priority___iv}) {
    nodes {
      name {
        de
        nl
        en 
      }
      slug {
        iv
      }
      headerImage {
        childImageSharp {
          fluid(maxWidth: 1600) { 
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
}
`
